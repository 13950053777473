import React, { Component } from "react";
import { Elements, StripeProvider, CardElement } from "react-stripe-elements";
import { loadStripe } from "@stripe/stripe-js";
import Header from "./components/header.js";
import Footer from "./components/footer.js";
import Donate from "./components/donate.js";
import DonationOptions from "./components/donation-options.js";
import LearnMoreButton from "./components/learn-more.js";
import SocialComponent from "./components/social-component.js";
import Checkout from "./pages/page-donate.js";
import { Link, withRouter } from "react-router-dom";
import heroImage from "./images/hero_image.png";
import "./App.scss";
const data = require("./data/data.json");

class App extends Component {
  renderBody = () => {
    return (
      <div>
        {data.body.map((paragraph) => {
          return <p>{paragraph}</p>;
        })}
      </div>
    );
  };

  render() {
    return (
      <div>
        <Header />
        <div className="topTitle">
          <h1>{data.title}</h1>
        </div>
        <div className="row">
          <div className="double-column">
            <div className="column">
              <div className="section-main">
                <div className="section-main-title">
                  <img src={heroImage}></img>
                  <div style={{ minWidth: "300px" }}></div>
                </div>

                <div className="section-main-description">
                <p>During Giving Week this March, Dune Jewelry will be donating 15% of net proceeds to EarthShare to support our oceans, ecosystems, and marine research efforts. PLUS we are giving a free gift with $125+ so you can show your support in style.</p>
<p>Please join us in our support of EarthShare by donating to one of these critical issue areas. Together we can create a more just, sustainable, and healthy planet.</p>
<p><b>Healthy Oceans</b></p>
<p>Your dedication to Healthy Oceans gives EarthShare partners the resources needed to reclaim hundreds of thousands of oyster shell bushels, keeping them out of landfills and replanting them for future generations of oyster growth—critical for cleaner waters. Our partners work alongside hundreds of local businesses and organizations to support the natural recovery process of reefs and coral reef restoration by restoring habitats and reintroducing genetically diverse coral species to marine ecosystems; ideal for biodiversity.</p>
<p><b>Thriving Ecosystems</b></p>
<p>When you support Thriving Ecosystems, you enable EarthShare partners to help millions of endangered marine species, including endangered turtle hatchlings on more than 60 beaches in 25 countries. Our partners organize coastal cleanups, removing and sorting thousands of pounds of debris to clean beaches and identify major sources of pollution. Working with local anglers, marine species are tracked and monitored, animals in distress rescued, and potentially dangerous debris is collected and removed (more than 683 miles of fishing line to date).</p>
<p><b>Caring Communities</b></p>
<p>By supporting Caring Communities, you empower EarthShare partners to fight for pollution and plastic-free seas as well as inclusive and accessible coastal communities. To date, these partners have led thousands of beach cleanups, collecting more than 650,000 pounds of trash on recreational beaches and making them more accessible to the community. And, since coastal ecosystems are 5 to 10 times more effective at removing carbon from the air, these partners also focus on planting, restoring, and protecting coastal trees and plant life, which creates local jobs and greater biodiversity.</p>
<p><b>Local Shores</b></p>
<p>When you give to Local Shores, EarthShare partners can support public education and legislation initiatives needed to protect the precious waterways of the Northeastern U.S. and the critical ecosystems that exist here. With programs to educate the public, innovative scientific research, and a commitment to marine animal conservation, our partners successfully advocate for cleaner, safer water—both in our oceans and in our homes. From local classroom education to the on-site cleanup of water contamination, these nonprofits put community first.</p>
<br></br>
<p>Dune Jewelry was founded in 2010 at Holly Daniels Christensen's kitchen table. She started capturing beach memories in the form of unique, sand-filled jewelry for friends and family. Inspiration became passion, and now Dune Jewelry is the one-and-only Experiential Jewelry Company capturing all of life's greatest moments.</p>
<p>In creating tangible reminders of experiences past, Holly has made it a part of Dune's mission to protect and preserve the places we love and hold dear for generations to come. Over the years Dune has donated over $250,000 to important global and coastal causes. We are excited to continue our Giving Journey with EarthShare to amplify impact by providing individuals, businesses, and nonprofits with inspiration and tools to work together for a just and sustainable world.</p>
<p>Shop Dune Jewelry Here: <a href="https://dunejewelry.com/best-selling-nautical-jewelry">https://dunejewelry.com/best-selling-nautical-jewelry</a></p>
<p>Learn More About EarthShare Here: <a href="https://www.earthshare.org">https://www.earthshare.org</a></p>
                </div>
              </div>
            </div>
          </div>

          <div className="column">
            <div className="section-donate">
              <Donate />
              <DonationOptions />
              <SocialComponent />
              <div class="section-donate-disclaimer">
                *While we work to ensure accuracy, the stats indicated on this
                page may not be an exact reflection of actual activity.
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(App);
