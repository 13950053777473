import React from 'react';
import { withRouter } from 'react-router-dom';
import './page-terms.scss';

class Terms extends React.Component {
  render() {
    return (
      <div>
        <button onClick={this.props.closeModal}>close</button>
        <div className="container-page-terms">
          <div className="container-page-terms-title">
            <center>
            <p>Please read these Terms of Use (“Terms” or “Terms of Use”) carefully. The Terms constitute an agreement between EarthShare, Inc. (“EarthShare”, “we”, “us”, or “our”) and the entity, organization, or individual accessing or using the EarthShare Platform (“you” or “your”), and govern the use of any services and offerings (“EarthShare Programs”) made available through our platform, (the “EarthShare Platform”, which includes the EarthShare Programs), and any content made available on the EarthShare Platform (the “EarthShare Content”). If you are using the EarthShare Programs as an employee of a participating employer organization that has a valid subscription (or other registration) with us and you are authorized to access and use the EarthShare Platform as part of their subscription or registration (“Employee User”), as noted below, certain provisions may not apply to you.</p>
<p>BY ACCESSING OR USING THE EARTHSHARE PLATFORM, EARTHSHARE PROGRAMS, OR EARTHSHARE CONTENT, CLICKING THE “I ACCEPT” BUTTON, ENTERING INTO AN APPLICABLE ORDER, OR COMPLETING THE REGISTRATION PROCESS, YOU ACCEPT AND AGREE TO BE BOUND BY THESE TERMS OF USE. IF YOU DO NOT AGREE TO BE BOUND BY THESE TERMS OF USE, PLEASE DO NOT ACCESS, BROWSE, OR OTHERWISE USE THE EARTHSHARE PLATFORM OR EARTHSHARE CONTENT. You must be 18 years of age or older to access or use the EarthShare Platform. Our collection and use of personal information in connection with the EarthShare Platform is described in our Privacy Policy, found at</p><a href="https://www.earthshare.org/privacy-statement" target="_self"></a><a>https://www.earthshare.org/privacy-statement</a>
<h4><p>Your Right to Use the EarthShare Platform and EarthShare Content</p></h4>
<p>The EarthShare Platform is a philanthropy technology platform that enables you to participate in the EarthShare Programs and connects environmental organizations or other third parties with employers, people, and communities to engage in strategic environmental programs for your internal business purposes or personal purposes (“Permitted Purpose”). Conditioned upon your full compliance with these Terms and obtaining an active subscription or other registration, you may access and use the EarthShare Platform and EarthShare Content solely for the Permitted Purpose.</p>
<h4><p>Use Restrictions</p></h4>
<p>You will not reproduce, adapt, modify, prepare derivative works of, distribute, license, sell, transfer, publicly display, publicly perform, transmit, stream, broadcast, or otherwise exploit the EarthShare Platform or EarthShare Content, except as permitted in these Terms. Further, you will not interfere or attempt to interfere with the proper working of the EarthShare Platform.</p>
<h4><p>Registration</p></h4>
<p>You must register in order to access or use the EarthShare Platform no matter what type of user you are. The number of authorized users, the duration of your use, and the availability of EarthShare Content, EarthShare Programs, or functionalities and features within the EarthShare Platform are subject to the type of registration you have made or subscription you purchase and your payment to us of the applicable subscription fees, if fees are required. If you are accessing or using the EarthShare Platform as an Employee User, you agree and acknowledge that your ability to access and use the EarthShare Platform and EarthShare Content and the availability of EarthShare Content, EarthShare Programs, and features or functionalities in the EarthShare Platform, are subject to the type of registration or subscription selected by that entity or organization and both their compliance and your compliance with these Terms.</p>
<h4><p>Your Account</p></h4>
<p>In connection with your use of the EarthShare Platform, you will be required to establish a user account (“Account”). In order to establish an Account, you may need to provide certain personal information about yourself, including your first and last names. All information provided during Account registration by you must be true and accurate. Collection and use of your personal information is subject to our Privacy Policy. You are entirely responsible for the security and confidentiality of your Account and password (including your Employee User’s Accounts if you are an entity or organization). Further, you are entirely responsible for any and all activities that occur under your Account, even where your username, password, and other access information or codes are used by someone else (including your Employer User’s Accounts if you are an entity or organization). You agree to immediately notify us of any unauthorized use of your Account or any other breach of security of which you become aware of or suspect (including your Employer User’s Accounts if you are an entity or organization). You are responsible for taking precautions and providing security measures best suited for your situation and intended use of the EarthShare Platform. Please note that anyone able to provide your personally identifiable information will be able to access your Account, so you should take reasonable steps to protect this information.</p>
<h4><p>Supplemental Terms for Certain EarthShare Programs</p></h4>
<p>You use of, and participation in, certain EarthShare Programs may be subject to additional terms (“Supplemental Terms”). Such Supplemental Terms will either be listed in these Terms or will be presented to you for your acceptance when you sign up to use or participate in the applicable EarthShare Programs. If the Terms of Use are inconsistent with the Supplemental Terms, the Supplemental Terms will control with respect to the applicable EarthShare Program to the extent of the inconsistency. All Supplemental Terms are part of and incorporated into these Terms of Use.</p>
<h4><p>EarthShare Platform as an Intermediary; Distributions to Charitable Organizations</p></h4>
<p>The EarthShare Platform is an online venue for charity giving and connecting environmental organizations or other third parties with employers, people, and communities to engage in strategic environmental programs. You acknowledge and agree that by engaging with a third party through the EarthShare Platform (including but not limited to, for purposes of charity giving, volunteering, or investment management of charitable donation), you enter into an agreement directly with the third party, and that EarthShare is not a party to such agreement. We disclaim all liability arising from or related to any such agreements between you and a third party. When EarthShare distributes funds via third-party payment services, it incurs fees for the use of those payment services. EarthShare will deduct the processing fees of those payment services when making distributions to the charitable organizations that you specify. In exceptional cases, EarthShare reserves the right to refuse to process distributions to charitable organizations that EarthShare learns have acted unethically or otherwise no longer qualify to remain on EarthShare’s list of approved charitable organizations. In that event, EarthShare would inform you (if you are the donor) of that refusal, and you would have the choice to withdraw the donation or redirect to another qualifying charitable organization. EarthShare essentially operates like a donor advised fund, and you therefore understand that your charitable gifts are irrevocable and unconditional contributions of assets when received and accepted by EarthShare, and that EarthShare retains exclusive legal control over those contributed assets.</p>
<h4><p>Payment for EarthShare Program Subscriptions</p></h4>
<p>Payment.</p> <p>During the Term, you will pay us for all fees at the due dates set forth in the applicable Order in accordance with these Terms, unless you are an Employer User, in which case the provisions of this Section 7(a) do not apply to you. “Order” means any order placed by you with us for purchase of a subscription(s) to the EarthShare Platform through any process as prescribed by us. The terms of an Order are part of and incorporated into these Terms. If you fail to pay the recurring subscription fee required to maintain your selected type of subscription, you will not be permitted to further use the EarthShare Platform or EarthShare Content and you will lose access to the EarthShare Platform and EarthShare Content. Subject to Section 10(c) below, all payment obligations are non-cancelable and all amounts paid are non-refundable, except for amounts paid in error that are not actually due under the applicable Order. Individuals subscribing to the EarthShare Platform can register for a free account and are responsible for a 2% fee deducted from funds deposited.</p>
<p>Third Party Payment Vendors.</p> <p>EarthShare uses various third-party payment services to handle and process your payments to us. Your use of those payment services is subject to the term of service imposed by the applicable service provider and you may be responsible for any fees imposed by those service providers for their payment services. If you are unable to determine or locate the applicable terms based on information provided to you at the time of payment, please contact us at info@earthshare.org for further information.</p>
<p>Taxes.</p> <p>Except as otherwise expressly specified in an applicable Order, fees and other charges described in the applicable Order do not include federal, state or local sales, foreign withholding, use, property, excise, service, or similar taxes (including any sales or value added taxes) (“Taxes”) now or hereafter levied, all of which are your responsibility. We will be responsible for all taxes based upon our net income.</p>
<h4><p>Your Conduct</p></h4>
<p>You will not use the EarthShare Platform or EarthShare Content for any unlawful or unauthorized purposes, or in a manner which is inconsistent with these Terms. You will comply with all applicable laws, rules, and regulations in your use of the EarthShare Platform and EarthShare Content. Abusive communications of any kind (including threats of abuse or retribution), profanity, and defamatory or discriminatory conduct are strictly prohibited and may result in immediate termination of access to the EarthShare Platform.</p>
<p>By using or accessing the EarthShare Platform, you represent, warrant, and agree that you will not do anything that is illegal, infringing, fraudulent, malicious, or could expose EarthShare or users of the EarthShare Platform to harm or liability (including, without limitation, through transmission of harmful code or offensive content, or access to or transmission of proprietary information), and you will not attempt, encourage, or facilitate any of the foregoing.</p>
<p>If you are an entity or organization that has purchased a subscription with us, or otherwise registered with us, you hereby represent, warrant, and agree that: (i) you have taken all necessary corporate action to authorize your agreement to these Terms and performance by you of these Terms; (ii) you have granted the person agreeing to these Terms on your behalf, sufficient authority to bind you to these Terms; (iii) you are solely responsible for your Employer User’s access and use of the EarthShare Platform and EarthShare Content, and you will ensure that they comply with these Terms; and (iv) you are liable for all acts and omissions of your Employer User in relation to their access and use of the EarthShare Platform and EarthShare Content, and their compliance with these Terms.</p>
<h4><p>Reservation of Rights; Ownership and Intellectual Property</p></h4>
<p>EarthShare, its affiliates, licensors, or suppliers own the title, copyright, and other intellectual property rights in the EarthShare Platform and EarthShare Content, and reserve all rights not expressly granted to you in these Terms. The EarthShare Platform and EarthShare Content are protected by copyright, trademark, and other intellectual property laws and treaties. All trademarks, service marks, logos, trade names, and any other proprietary designations of EarthShare used in the EarthShare Platform or EarthShare Content are trademarks or registered trademarks of EarthShare or our suppliers. You may not use any of our trademarks, logos, or trade dress in connection with any product or service that is not owned or provided by us, in a manner that is likely to cause confusion, and in any manner that disparages us or our suppliers.</p>
<h4><p>Term and Termination</p></h4>
<p>Term.</p> <p>These Terms of Use shall commence on the earlier of, (i) the date you accept these Terms of Use, (ii) the subscription start date specified in an applicable Order, or (iii) the date you first registered for or otherwise accessed or used the EarthShare Platform, and will remain in full force and effect until the subscription end date specified in an applicable Order, end date provided at the time of registration, or terminates in accordance with these Terms of Use (the “Term”).</p>
<p>Termination by Us.</p> <p>We may terminate these Terms or block access to, suspend, or terminate your access to the EarthShare Platform, with or without notice, if we, in our sole discretion, determine that you are in breach of these Terms of Use. If we discontinue your ability to access the EarthShare Platform or create an Account, you agree that you will not attempt to re-register with or access the EarthShare Platform, including by using a different username. This provision applies to all accounts, including those of Employee Users.</p>
<p>Termination by You.</p> <p>You may terminate these Terms by written notice if we breach a material provision of these Terms and fail to remedy such breach within thirty (30) days following receipt of your written notice of such breach, unless you are an Employee User, in which case this section 10(c) does not apply to you. If you terminate these Terms for our breach of a material provision, we will provide you with a refund of any pre-paid fees that have not been used as of the date of termination.</p>
<p>Effect of Termination.</p> <p>Upon the expiration or termination of these Terms, you will no longer be able to access or use your Account, the EarthShare Platform, or the EarthShare Content. All provisions of these Terms that should survive termination, will survive, including ownership provisions, warranty disclaimers, indemnification, and limitations of liability.</p>
<h4><p>Support</p></h4>
<p>Unless otherwise specified in an applicable Order, any support services for the EarthShare Platform will be provided at EarthShare’s sole discretion, and EarthShare may elect to not provide support services for the EarthShare Platform without any liability o you. If you have any general enquiries about the EarthShare Platform or EarthShare Content, you should address them to us via the “Contact Information” section set forth below.</p>
<h4><p>Modifications to the EarthShare Platform</p></h4>
<p>We reserve the right to discontinue your access to any EarthShare Programs. EarthShare Content, or feature provided or made available to you through the EarthShare Platform. We reserve the right to change, remove, delete, restrict, or block access to, stop providing, or stop supporting all or any part of the EarthShare Platform or EarthShare Content without notice or liability.</p>
<h4><p>Links to Third Parties</p></h4>
<p>If the EarthShare Platform or EarthShare Content make available any links to third-party websites, such links are provided for convenience only. We do not review or control these third-party websites and are not responsible for any third-party website or the content of those sites. We will not be liable for damages associated with links to third-party websites.</p>
<h4><p>User Feedback</p></h4>
<p>By submitting any ideas, feedback, or proposals (“Feedback”) to us through the EarthShare Platform or other means, you acknowledge and agree that: (a) your Feedback does not contain confidential or proprietary information of you or any third party; (b) we are not under any obligations of confidentiality with respect to the Feedback; (c) we may freely use, reproduce, distribute, and otherwise exploit the Feedback for any purpose; and (d) you are not entitled to any compensation of any kind from us.</p>
<h4><p>Disclaimer of Warranties</p></h4>
<blockquote><p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, YOU ASSUME ALL RISKS ARISING OUT OF YOUR USE OF THE EARTHSHARE PLATFORM AND EARTHSHARE CONTENT. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE EARTHSHARE PLATFORM, EARTHSHARE CONTENT, AND SUPPORT SERVICES (IF ANY) ARE PROVIDED AS IS, AS AVAILABLE, AND WITH ALL FAULTS AND WITHOUT WARRANTIES, REPRESENTATIONS AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE MAKE NO ASSURANCE, WARRANTY, CONDITION OR DUTY OF OR REGARDING: FUNCTIONALITY; PRIVACY; SECURITY; ACCURACY; AVAILABILITY; REPAIRS; LACK OF NEGLIGENCE, INTERRUPTIONS, VIRUSES OR OTHER HARMFUL CODE, COMPONENTS, OR TRANSMISSIONS; OR THE NATURE OR CONSEQUENCES OF AVAILABLE CONTENT. THE CONTENT OR ANY OTHER INFORMATION MADE AVAILABLE THROUGH THE SERVICE DO NOT CONSTITUTE ADVICE OF ANY KIND AND SHOULD NOT BE RELIED UPON IN ANY WAY. WE ARE NOT LIABLE FOR ANY OMISSIONS OR ERRORS OF THE EARTHSHARE PLATFORM OR EARTHSHARE CONTENT. WE, OUR AFFILIATES, LICENSORS, AND SUPPLIERS MAKE NO WARRANTY AND HEREBY DISCLAIM ANY IMPLIED, STATUTORY OR OTHER WARRANTY, INCLUDING ANY WARRANTY OF TITLE, QUIET ENJOYMENT, NON-INFRINGEMENT, MERCHANTABILITY, MERCHANTABLE QUALITY, OR FITNESS FOR A PARTICULAR PURPOSE. THE ENTIRE RISK (IN CONNECTION WITH THE EARTHSHARE PLATFORM AND EARTHSHARE CONTENT) AS TO SATISFACTORY QUALITY, PERFORMANCE, ACCURACY AND EFFORT IS WITH YOU. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR LIMITATIONS AS SPECIFIED HERE, AND TO THE EXTENT PROHIBITED BY LAW, SUCH EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU.</p></blockquote>
<h4><p>Exclusion of Certain Damages</p></h4>
<blockquote><p>IN NO EVENT WILL EARTHSHARE, OUR AFFILIATES, LICENSORS, OR SUPPLIERS BE LIABLE UNDER OR IN CONNECTION WITH THESE TERMS OR ITS SUBJECT MATTER UNDER ANY LEGAL OR EQUITABLE THEORY (INCLUDING, BREACH OF CONTRACT, TORT, STATUTE, OR OTHERWISE) FOR (I) ANY PUNITIVE, INCIDENTAL, SPECIAL, INDIRECT AND CONSEQUENTIAL DAMAGES, (II) LOST PROFITS OR REVENUES, OR (III) DAMAGES RESULTING FROM LOST OR CORRUPTED DATA OR BUSINESS INTERRUPTION, REGARDLESS OF WHETHER OR NOT EARTHSHARE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY AS SPECIFIED IN THIS SECTION 16, AND TO THE EXTENT PROHIBITED BY LAW, SUCH EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU.</p></blockquote>
<h4><p>Limitation of Liability and Exclusive Remedy</p></h4>
<blockquote><p>IN NO EVENT WILL EARTHSHARE’S TOTAL LIABILITY TO YOU FOR ALL DAMAGES (OTHER THAN AS MAY BE REQUIRED BY APPLICABLE LAW IN CASES INVOLVING PERSONAL INJURY) EXCEED THE GREATER OF (I) FIVE DOLLARS ($5), AND (II) THE FEES PAID BY YOU TO US DURING THE TWELVE (12) MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO LIABILITY. THIS IS YOUR EXCLUSIVE REMEDY. THE FOREGOING LIMITATIONS WILL APPLY EVEN IF THE ABOVE STATED REMEDY FAILS OF ITS ESSENTIAL PURPOSE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY AS SPECIFIED IN THIS SECTION 17, AND TO THE EXTENT PROHIBITED BY LAW, SUCH EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU.</p></blockquote>
<h4><p>Indemnification</p></h4>
<p>You agree to release, discharge, defend, indemnify and hold harmless EarthShare and its parents, subsidiaries, officers, directors, employees and agents, assigns, licensors and suppliers from and against any costs, expenses, or damages of any kind (including, without limitation, legal and accounting fees), related to or arising out of a claim or demand by a third party based on (a) your access to or use in any way of the EarthShare Platform or EarthShare Content, (b) your breach of these Terms, (c) your failure to comply with applicable law, or (d) your violations of any rights of another. We will provide you with prompt notice of any such claim, suit or proceeding and reasonably cooperate with you, at your expense, in your defense of any such claim, suit or proceeding.</p>
<h4><p>Users Outside the United States</p></h4>
<p>We make no representation that the EarthShare Platform or EarthShare Content is appropriate or available for use in locations outside the United States. Those who choose to access the EarthShare Platform from locations outside the United States do so at their own volition and are responsible for compliance with applicable local laws. You may not use or export the EarthShare Content in violation of United State export laws and regulations.</p>
<h4><p>Changes to the Terms</p></h4>
<p>These Terms may be revised or updated from time to time in our sole discretion. All changes are effective immediately when we post them on the EarthShare Platform, and apply to all access to and use of the EarthShare Platform and EarthShare Content thereafter. Your continued use of the EarthShare Platform or EarthShare Content, following the posting of the revised Terms constitutes your acceptance of the changes. You are advised to check the Terms from time to time for any updates or changes that may impact you. If you do not agree with the revised Terms, you should discontinue your use of the EarthShare Platform and EarthShare Content.</p>
<h4><p>Governing Law; Jurisdiction and Venue</p></h4>
<p>These Terms and the relationship between you and EarthShare will be governed and construed by the laws of the District of Columbia without regard to conflict of law provisions. You hereby consent and submit to the personal and exclusive jurisdiction of the state and federal courts of the District of Columbia in any action or dispute related to these Terms.</p>
<h4><p>General</p></h4>
<p>Entire Agreement.</p> <p>These Terms and other terms herein incorporated by reference, constitute the entire agreement between you and EarthShare, superseding any prior agreement, whether oral or written.</p>
<p>Severability.</p> <p>If any provision of these Terms is found by a court of competent jurisdiction to be invalid, the parties agree that the court should endeavor to give effect to the parties’ intentions as reflected in the provision, and the other provisions of these Terms remain in full effect.</p>
<p>Waiver.</p> <p>The failure of EarthShare to exercise or enforce any right or provision of the Terms will not constitute a waiver of such right or provision.</p>
<p>Assignment.</p> <p>You will not assign or transfer these Terms, by operation of law or otherwise, without our prior written consent. Any attempt by you to assign or transfer these Terms, without such consent, will be null and of no effect. We may assign or transfer these Terms, at our sole discretion and without restriction or notice to you. Subject to the foregoing, these Terms will bind and inure to the benefit of the parties, their successors, and permitted assigns.</p>
<h4><p>Notices</p></h4>
<p>We may deliver notice to you under these Terms by means of electronic mail, a general notice on or through the Service, or by written communication delivered by first class U.S. mail. You may give notice to us at any time by letter delivered by first-class postage prepaid U.S. mail or overnight courier to the following address: EarthShare, Inc. 1717 K Street NW, Suite 900 Washington, DC 20006</p>
<h4><p>Copyright Infringement, Notices, and Takedown Procedures</p></h4>
<p>We respect the intellectual property rights of others and expect our users to do the same. It is our policy, at our discretion and when appropriate, to remove infringing materials or terminate the Accounts of users who may infringe or repeatedly infringe the copyright of third parties. If you believe, in good faith, that any materials on the EarthShare Platform infringe upon your copyright, please notify us at: info@earthshare.org.</p>
<p>To submit a copyright infringement notification to us, please submit a notification pursuant to the Digital Millennium Copyright Act (DMCA) by providing our DMCA Designated Agent with the following information in writing:</p>
<p>a) A physical or electronic signature of a person authorized to act on behalf of the owner of the material;</p>
<p>b) Identification of the copyrighted work claimed to have been infringed;</p>
<p>c) Identification of the material that is claimed to be infringing including information reasonably sufficient to permit us to locate it on the EarthShare Platform;</p>
<p>d) Your name and the mailing address, telephone number, and email address where we can contact you;</p>
<p>e) A statement that you have a good faith belief that use of the material on the EarthShare Platform is not authorized by the copyright owner, its agent, or the law; and</p>
<p>f) A statement that the information in your notice is accurate, and under penalty of perjury, that you are authorized to act on behalf of the owner of an exclusive right that is allegedly infringed. Notwithstanding the foregoing, we do not guarantee any action based upon the receipt of your notice. You acknowledge that if you fail to comply with all of the requirements of the preceding paragraph, your DMCA notice may not be valid.</p>
<p></p>
<h4><p>CONTACT INFORMATION</p></h4>
<p>If you have any questions about these Terms, or want to contact us for any reason, please direct all correspondence to: info@eartshare.org.</p>
            </center>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Terms);
